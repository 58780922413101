@tailwind base;

@tailwind components;

@tailwind utilities;

.logo {
    @apply cursor-pointer;
    background: url("images/logo.svg") no-repeat;
    height: 16px;
    width: 105px;
}

nav menu li {
    @apply mx-2 py-1 cursor-pointer;
}

.selected {
    @apply border-gendra-secondary border-b-2 border-solid;
}

[x-cloak] { display: none; }